import { defineStore } from 'pinia';

export const useFaqStore = defineStore('faqStore', {
  state: () => ({
    list: null
  }),
  actions: {
    async getList() {
      const { data: faqs } = await useFetch('/api/v2/faqs');
      if (faqs && faqs !== null) this.list = faqs;
    }
  }
});