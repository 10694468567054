<script setup lang="ts">
	import { ref } from 'vue';
	import { useFaqStore } from '~/stores/FaqStore';

	const faqStore = useFaqStore();
	const active = ref(0);

	const toggle = (id: number) => {
		if (active.value == id) {
			active.value = 0;
		} else {
			active.value = id;
		}
	};
</script>

<template>
	<div class="section faq">
		<div class="faq__title title">Часто задаваемые вопросы</div>
		<div class="faq__content">
			<div class="faq__item" v-for="faq in faqStore.list" :key="faq.id">
				<div class="faq__item-head" :class="{active: faq.id == active}" @click="toggle(faq.id)">
					<div class="faq__item-title">{{faq.question}}</div>
					<div class="faq__item-btn"></div>
				</div>
				<div class="faq__item-content" :class="{active: faq.id == active}">
					{{faq.answer}}
				</div>
			</div>
		</div>
	</div>
</template>